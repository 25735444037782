<template>
  <transition name="fade">
    <div class="im" v-show="isShow">
      <div class="mask" @click="hide"></div>

      <Lemon-imui
        ref="IMUI"
        class="imui"
        width="1000px"
        height="660px"
        avatarCricle
     
        :user="user"
        @send="handleSend"
        @pull-messages="handlePullMessages"
        @change-contact="handleChangeContact"
        @message-click="handleMsgClick"
        :currentContact="currentContact"
      >
        <template slot="message-title" slot-scope="scope">
          <div class="msg-title">
            <div class="msg-title__left">
              <h2>{{ scope.displayName }}</h2>
            </div>
            <div class="msg-title__right">
              <img
                src="https://th.bing.com/th/id/R.0f2c503e32fbf9c9218341875b1aa7b8?rik=CT7sBKpQhE2jBw&riu=http%3a%2f%2fbpic.588ku.com%2felement_pic%2f01%2f51%2f88%2f435745d83b7ec5d.jpg&ehk=Ggh%2fTS8%2f2Hs601OcX2ZN8b0nsWwhmogRE1Jck9e%2bKac%3d&risl=&pid=ImgRaw&r=0"
                title="关闭"
                @click="hide"
              />
            </div>
          </div>
        </template>
      </Lemon-imui>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import conifg from "../../utils/config.js"
import $ from "jquery";
// import {
// 	LIVESOCKET,
// 	SERVERTAPI
// } from '../../../config/index.js'
import pinyin4js from "pinyin4js";
import axios from "axios";
// import emoji from './emoji'
import WebsocketHeartbeatJs from "websocket-heartbeat-js";

export default {
  name: "IM",

  data() {
    return {
      isShow: false,
      ws: null,
      current: 1,
      isPreview: false,
      currentUserid: "",
      imgList: [],
      // emojiList: [
      //   "😀", "😁", "😂", "😃", "😄", "😅", "😆", "😉", "😊", "😋", "😎", "😍", "😘", "😗", "😙", "😚", "☺", "😇", "😐", "😑",
      //   "😶", "😏", "😣", "😥", "😮", "😯", "😪", "😫", "😴", "😌", "😛", "😜", "😝", "😒", "😓", "😔", "😕", "😲", "😷", "😖",
      //   "😞", "😟", "😤", "😢", "😭", "😦", "😧", "😨", "😬", "😰", "😱", "😳", "😵", "😡", "😠"
      // ],
      // msgSound: `${SERVERTAPI}/static/message/message.mp3`,
      audio: null,
      msgList: [],
      contact: {
        user_id:0,
        unread:0
      },
      currentContact:{},
      shop: {},
      chatlist: [],
      last_id: 0,
    };
  },

  computed: {
    ...mapState(["userInfo", "token"]),

    user() {
      return {
        id: this.$store.state.userData?.userinfo?.user_id || "",
        // avatar: this.userInfo.headimgurl,
        // displayName: this.userInfo.user_name
        displayName: this.$store.state.userData?.userinfo?.nickname || "",
        avatar: this.$store.state.userData?.userinfo?.avatar || "",
        // a: 11111,
      };
    },
    // currentContact() {
    //   return {
    //     id: 1504,
    //     user_id: 629515,
    //     displayName: "mollymolly",
    //     avatar:
    //       "https://www.modclothsapp.vip/uploads/20240912/5387804b3a053f342c841d40cca5d439.png",
    //     unread: 0,
    //     lastContent: "😊",
    //   };
    // },
  },

  watch: {
    token: {
      handler(v) {
        if (v) {
          this.initIM();
        } else {
          this.ws && this.ws.close();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.audio = new Audio(this.msgSound);
    // this.chatlist = this.$store.state.chatlist
    // this.initContacts();
    // this.handlePullMessages();
    // this.show()
  },

  methods: {
    show(user, other) {
      // if (!user.id) {
      //   document.querySelector('body').style.overflow = 'hidden'
      //   this.isShow = true

      //   return false
      // }

      // this.currentContact = {
      //   avatar: this.$baseUrl + "/assets/img/avatar.png",
        
      //   lastContent:"",
      //   unread:0,

      //   id: user.user_id || user.id,
      //   username: user.username,
      // }
      // this.contact = {
      //   avatar: this.$baseUrl + "/assets/img/avatar.png",
        
      //   lastContent:"",
      //   unread:0,

      //   id: user.user_id || user.id,
      //   username: user.username,
      // }


      console.log(this.contact,22222)

      this.isShow = true;
      this.shop = user || { id: 0 };

      let arr = [];

      this.$store.state.chatlist.forEach((item) => {
        if (item.id == this.shop.id ||item.id ==  this.shop.shop_id) {
          
          arr.unshift(item);
        } else {
          arr.push(item);
        }
      });

      this.initContacts(arr);

      const contacts = this.$refs.IMUI.getContacts();


      console.log(this.$refs.IMUI)

     
        if (!contacts.map((v) => v.user_id).includes(user.user_id || user.id )) {
        setTimeout(() => {
          this.$refs.IMUI.initContacts([
            {
              user_id: user.user_id || user.id,
              id: user.id ,
              displayName: user.shopname || user.username,
              avatar: user.avatar,
              // index: pinyin4js.convertToPinyinString(user.username.substr(0, 1), '', pinyin4js.FIRST_LETTER).toLocaleUpperCase(),
              unread: 0,
              lastContent: this.$refs.IMUI.lastContentRender({
                type: "text",
                content: " ",
              }),
              lastSendTime: new Date().getTime(),
            },

            ...contacts,
          ]);

          // this.$nextTick(() => {
          
          //   // this.$refs.IMUI.currentContact = arr[0]
          //   alert(JSON.stringify(user))
          //   this.$refs.IMUI.changeContact( user.id);
          //   // this.handlePullMessages(arr[0],() => {})
          //   document.querySelector("body").style.overflow = "hidden";
          //   this.isShow = true;
          // });
        }, 300);
      }
      
    

      this.$nextTick(() => {
       
        this.$refs.IMUI.changeContact(user.user_id || user.id);
       
        this.isShow = true;
      });
   
    },

    initIM() {
      this.ws = new WebsocketHeartbeatJs({
        url: conifg.socketurl,
        pingTimeout: 15000,
        pongTimeout: 10000,
        reconnectTimeout: 3000,
        pingMsg: JSON.stringify({
          type: "ping",
          id: this.token,
        }),
      });
      this.ws.onopen = () => {
        console.log("连接成功!");
        this.$refs.IMUI.initEditorTools([
          // {
          //   name: "emoji",
          // },
          {
            name: "uploadImage",
          },
        ]);
        // this.$refs.IMUI.initEmoji(this.emojiList);
      };

      this.ws.onmessage = (e) => {
        const data = JSON.parse(e.data);

        if (data.type === "init") {
          this.$axios.post("/api/wanlshop/chat/shake", {
            client_id: data.client_id,
          });
        } else if (data.type == "ping") {
          // 通过 WebSocket 连接发送数据
          this.ws.send(JSON.stringify({ type: "pong" }));
          // 客服消息
        } else if (data.type == "service") {
          // 全局通知
          uni.$emit("onService", data);
          // 消息提示
          dispatch("notice", { type: data.type, data: null });
          // 即时通讯消息
        } else if (data.type == "chat") {
          // this.$message.error(data.form.name + "发来一条消息")

          //           {
          //   "id": "10657",
          //   "type": "chat",
          //   "to_id": "627931",
          //   "form": {
          //     "id": "628580",
          //     "avatar": "https://www.modclothsapp.vip/uploads/20240524/068187c78dc7664cb9996bca386b99b4.jpg",
          //     "name": "23RY9mWB"
          //   },
          //   "message": {
          //     "type": "text",
          //     "content": {
          //       "text": "666"
          //     }
          //   },
          //   "createtime": "1729016469838"
          // }

          // id: v.id,
          //                   status: "succeed",
          //                   type: v.message?.type == "text" ? "text" : "image",
          //                   sendTime: v.createtime * 1000,
          //                   content: v.message?.type == "text" ? v.message.content.text : v.message.content.url,

          //                   fromUser: {
          //                     id: v.form.id,
          //                     displayName: v.form.name,
          //                     avatar: v.form.avatar,
          //                   },

          // 627931   628580
          // toContactId: this.$store.state.userData.userinfo.id == data.id ? data.form.id : data.id ,

          let id = "";
          let displayName = "";
          let avatar = "";

          if (this.contact.id) {
            if (this.contact.user_id == data.form.id) {
              
            } else {
              // alert(2)
              //   let arr =  this.$store.state.chatlist;
              // arr.forEach(item => {
              //   if(item.id == data.form.id){
              //     item.count++;
              //   }
              // })
              // this.$store.state.chatlist = arr;
              // $(`[title='${data.form.name}'] .lemon-contact__content span`).html(data.message.content.text || "[Picture]")
              // let num = $(`[title='${data.form.name}']  .lemon-badge__label`).html() || 0

              // if(num){
              //   $(`[title='${data.form.name}']  .lemon-badge__label`).html(num*1 + 1)
              // }else{
              //   $(`[title='${data.form.name}']  .lemon-badge`).append('<span class="lemon-badge__label">1</span>')
              // }

              // this.$store.state.chat_number ++;

              let arr = this.$refs.IMUI.contacts;

              arr.forEach((item) => {
                if (item.user_id == data.form.id) {
                  item.unread = item.unread + 1;
                  item.lastContent = data.message.content.text || "[Picture]";
                }
              });
              this.$refs.IMUI.contacts = arr;

              this.$store.state.chat_number++;
            }
          } else {
            // alert(3)

            //   $(`[title='${data.form.name}'] .lemon-contact__content span`).html(data.message.content.text || "[Picture]")
            //   let num = $(`[title='${data.form.name}'] .lemon-badge__label`).html()  || 0
            //  console.log(num,"------------------------------------------")
            //   if(num){
            //     $(`[title='${data.form.name}']  .lemon-badge__label`).html(num*1 + 1)
            //   }else{
            //     $(`[title='${data.form.name}']  .lemon-badge`).append('<span class="lemon-badge__label">1</span>')

            //   }

            let arr = this.$refs.IMUI.contacts;

            arr.forEach((item) => {
              if (item.user_id == data.form.id) {
                item.unread = item.unread + 1;
                item.lastContent = data.message.content.text || "[Picture]";
              }
            });
            this.$refs.IMUI.contacts = arr;

            this.$store.state.chat_number++;

            //             [
            //     {
            //         "id": 627931,
            //         "user_id": 627931,
            //         "displayName": "VPFTdk7Q",
            //         "avatar": "https://www.modclothsapp.vip/uploads/20240303/682abb0bf9eaf12c73da0dcda4ddc4e3.jpg",
            //         "unread": 99,
            //         "lastContent": "1122"
            //     },
            //     {
            //         "id": 629778,
            //         "user_id": 629778,
            //         "displayName": "zsmrKt0Z",
            //         "avatar": "https://www.modclothsapp.vip/assets/img/avatar.png",
            //         "unread": 99,
            //         "lastContent": "212133"
            //     },
            //     {
            //         "id": 629553,
            //         "user_id": 629553,
            //         "displayName": "GBvaOj8P",
            //         "avatar": "https://www.modclothsapp.vip/assets/img/avatar.png",
            //         "unread": 99,
            //         "lastContent": "😁😁"
            //     },
            //     {
            //         "id": 628584,
            //         "user_id": 628584,
            //         "displayName": "afXlVsiJ",
            //         "avatar": "https://www.modclothsapp.vip/uploads/20240622/6bd78839f20aadad91c4d6a8cbec4e3c.jpg",
            //         "unread": 0,
            //         "lastContent": "Thank you for purchasing the product in the store I run. This order can take 3 days to ship to you. Please bear with me"
            //     }
            // ]
          }

          if (this.$store.state.userData.userinfo.shop) {
            id = this.contact.id;

            displayName = this.contact.displayName;
            avatar = this.contact.avatar;
          } else {
            id = this.contact.id;
            displayName = this.contact.displayName;
            avatar = this.contact.avatar;
          }

          const msg = {
            id: new Date().getTime(),
            status: "succeed",
            type: data.message.type == "text" ? "text" : "image",
            sendTime: new Date().getTime(),
            content:
              data.message?.type == "text"
                ? data.message.content.text
                : data.message.content.url,
            // content: this.formatMsg("88888888", data.message.type),
            toContactId: id,
            fromUser: {
              id: id,
              displayName: displayName,
              avatar: avatar,
            },
          };

          // console.log(data);

          if (this.contact.user_id == data.form.id) {
            this.$refs.IMUI.appendMessage(msg, true);
          }
        } else if (data.type == "order") {
          // 全局更新,分析订单类型: 待收货（已发货）交易物流+1
          // 接受其他 签收，退款申请同意，拒绝
          dispatch("storage", { type: data.type });
          // 消息提示
          dispatch("notice", {
            type: "order",
            data: {
              title: data.title,
              subtitle: "",
              content: data.content,
              jsondata: JSON.stringify({
                modules: data.modules,
                modules_id: data.modules_id,
              }),
            },
          });
          // 通知消息
        } else if (data.type == "notice") {
          dispatch("storage", { type: data.type });
          // 消息提示
          dispatch("notice", {
            type: data.type,
            data: {
              title: "标题",
              subtitle: "副标题",
              content: "内容",
              jsondata: JSON.stringify({
                id: 0,
              }),
            },
          });
        } else if (data.type == "live") {
          // 全局通知
          uni.$emit("onLive", data);
        } else {
          config.debug ? console.log("未知消息: " + JSON.stringify(data)) : "";
        }
      };
      // this.ws.onmessage = (e) => {
      //   const data = JSON.parse(e.data);
      //   console.log(data);
      //   if (data.type === "init") {
      //     const client_id = data.client_id;
      //     if (this.token) {
      //       this.ws.send(
      //         JSON.stringify({
      //           type: "bind_id",
      //           id: this.token,
      //           client_id,
      //         })
      //       );
      //     }
      //     return false;
      //   }
      //   if (data.type === "imcountnum") {
      //     this.$store.commit("SET_MSG_COUNT", data.data.imcountnum);
      //   }
      //   if (data.type === "say") {
      //     const msg = {
      //       id: new Date().getTime(),
      //       status: "succeed",
      //       type:
      //         data.data.message_type === "img"
      //           ? "image"
      //           : data.data.message_type,
      //       sendTime: new Date().getTime(),
      //       content: this.formatMsg(data.data.message, data.data.message_type),
      //       toContactId: data.data.fromid,
      //       fromUser: {
      //         id: data.data.fromid,
      //         displayName: data.data.from_username,
      //         avatar: data.data.from_headimgurl,
      //       },
      //     };
      //     this.$refs.IMUI.appendMessage(msg, true);
      //     if (this.$refs.IMUI.getCurrentContact().id === data.data.fromid) {
      //       this.ws.send(
      //         JSON.stringify({
      //           type: "readmsg",
      //           data: {
      //             fromid: this.token,
      //             toid: data.data.fromid,
      //           },
      //         })
      //       );
      //     }
      //     this.ws.send(
      //       JSON.stringify({
      //         type: "imcountnum",
      //         id: this.token,
      //       })
      //     );
      //     this.audio.play();
      //   }
      //   if (data.type === "readmsg") {
      //     this.ws.send(
      //       JSON.stringify({
      //         type: "chatlist",
      //         data: {
      //           fromid: this.token,
      //         },
      //       })
      //     );
      //     this.ws.send(
      //       JSON.stringify({
      //         type: "imcountnum",
      //         id: this.token,
      //       })
      //     );
      //   }
      //   if (data.type === "chatlist") {
      //     this.initContacts(data.data);
      //     return false;
      //   }
      //   if (data.type === "bind_id") {
      //     this.ws.send(
      //       JSON.stringify({
      //         type: "chatlist",
      //         data: {
      //           fromid: this.token,
      //         },
      //       })
      //     );
      //     this.ws.send(
      //       JSON.stringify({
      //         type: "imcountnum",
      //         id: this.token,
      //       })
      //     );
      //   }
      // };
      // this.ws.onreconnect = function () {
      //   console.log("重连中");
      // };
      // this.ws.onclose = () => {
      //   console.log("关闭成功!");
      // };
    },

    handleMsgClick(e, key, msg) {
      if (msg.type === "image") {
        if (e.target.getAttribute("src") === msg.content) {
          this.$hevueImgPreview(msg.content);
        }
      }
    },

    handleChangeContact(contact) {
      // alert(1)
      console.log(this.$refs.IMUI.currentContact)
     
    
      if (this.$refs.IMUI.currentContact.unread) {
        this.$axios
          .post("/api/wanlshop/chat/clear", {
            id: this.$refs.IMUI.currentContact.user_id,
          })
          .then((res) => {});
      }
      if(contact){
        this.contact = contact;

      }
      
      // console.log( this.contact)

    

      // if (this.$refs.IMUI.currentContact.unread) {
      //   this.$axios
      //     .post("/api/wanlshop/chat/clear", {
      //       id: this.$refs.IMUI.currentContact.user_id,
      //     })
      //     .then((res) => {});
      // }

      // console.log(contact);

      if(this.$refs.IMUI.currentContact.user_id){
        this.$refs.IMUI.clearMessages(contact.id);
        this.$refs.IMUI.getMessages(contact.id)
      }

      // console.log(this.$refs.IMUI);
     
      // console.log();

      $(`[title='${contact.displayName}']  .lemon-badge__label`).remove();

      this.$store.state.chat_number =
        this.$store.state.chat_number - contact.unread;

      this.last_id = 0;
      this.msgList = [];
      // this.handlePullMessages(contact,null)

      this.scrollToBottom();
    },

    formatMsg(content, msgType) {
      if (content.indexOf("img[") === 0 && msgType === "img") {
        return content.substring(4, content.length - 1);
      }

      if (msgType === "text" && /face\[([\u4e00-\u9fa5]+)\]/.test(content)) {
        return this.mapEmojiValue(content);
      }

      return content;
    },

    scrollToBottom() {
      let i = 0;
      setTimeout(() => {
        // console.log(document.querySelectorAll(".lemon-message__content img"));
        document
          .querySelectorAll(".lemon-message__content img")
          .forEach((v, index) => {
            const src = v.getAttribute("src");
            v.onload = () => {
              i = i + 1;

              if (
                i ===
                document.querySelectorAll(".lemon-message__content img").length
              ) {
                document.querySelector(".lemon-messages").scrollTo(0, 100000);
              }
            };

            document.querySelector(".lemon-messages").scrollTo(0, 100000);
          });
      }, 50);
      document.querySelector(".lemon-messages").scrollTo(0, 100000);
    },

    handlePullMessages(contact, next) {
    console.log(this.contact,1111111111)

      this.$axios
        .post("/api/wanlshop/chat/history2", {
          last_id: this.last_id,
          to_id: this.contact.user_id || this.shop.id,
        })
        .then((res) => {
          if (res.status === 200) {
            let list = res.data.data || []
            list =  list

              .map((v) => {
                return {
                  id: v.id,
                  status: "succeed",
                  type: v.message?.type == "text" ? "text" : "image",
                  sendTime: v.createtime * 1000,
                  content:
                    v.message?.type == "text"
                      ? v.message.content.text
                      : v.message.content.url,
                  toContactId: v.form.id,
                  fromUser: {
                    id: v.form.id,
                    displayName: v.form.name,
                    avatar: v.form.avatar,
                  },
                };
              })
              .reverse();

            if (this.last_id === 0) {
              this.scrollToBottom();
            }

            if (list.length === 30) {
              this.last_id = this.last_id = list[0].id;
            }
            this.msgList = [];
            next(list, list.length < 30 ? true : false);
          }
        });
    },

    mapEmojiValue(str) {
      let value = "";
      let obj = {};
      if (!/face\[([\u4e00-\u9fa5]+)\]/g) {
        return str;
      }

      let len = str.match(/face\[([\u4e00-\u9fa5]+)\]/g).length;
      str.match(/face\[([\u4e00-\u9fa5]+)\]/g).forEach((v, index) => {
        if (this.emojiList.filter((e) => e.value === v).length) {
          const emojiName = `[!${
            this.emojiList.filter((e) => e.value === v)[0].name
          }]`;
          if (obj[index - 1]) {
            if (len - 1 === index) {
              value = obj[index - 1].replace(v, emojiName);
            } else {
              obj[index] = obj[index - 1].replace(v, emojiName);
            }
          } else {
            if (len === 1) {
              value = str.replace(v, emojiName);
            } else {
              obj[index] = str.replace(v, emojiName);
            }
          }
        }
      });

      return value || str;
    },

    getEmojiValue(str) {
      let value = "";
      let obj = {};
      if (!str.match(/\[!(\w+)\]/g)) {
        return str;
      }
      let len = str.match(/\[!(\w+)\]/g).length;
      str.match(/\[!(\w+)\]/g).forEach((v, index) => {
        if (
          this.emojiList.filter((e) => e.name === v.match(/\[!(\w+)\]/)[1])
            .length
        ) {
          if (obj[index - 1]) {
            if (len - 1 === index) {
              value = obj[index - 1].replace(
                v,
                this.emojiList.filter(
                  (e) => e.name === v.match(/\[!(\w+)\]/)[1]
                )[0].value
              );
            } else {
              obj[index] = obj[index - 1].replace(
                v,
                this.emojiList.filter(
                  (e) => e.name === v.match(/\[!(\w+)\]/)[1]
                )[0].value
              );
            }
          } else {
            if (len === 1) {
              value = str.replace(
                v,
                this.emojiList.filter(
                  (e) => e.name === v.match(/\[!(\w+)\]/)[1]
                )[0].value
              );
            } else {
              obj[index] = str.replace(
                v,
                this.emojiList.filter(
                  (e) => e.name === v.match(/\[!(\w+)\]/)[1]
                )[0].value
              );
            }
          }
        }
      });

      return value || str;
    },

    handleSend(e, next, file) {
      let lastid = 1;
      if (this.msgList.length) {
        lastid = this.msgList[this.msgList.length - 1].id;
        lastid++;
      }

      if (e.type === "text") {
        this.$axios.post("/api/wanlshop/chat/send", {
          id: lastid,
          type: "chat",
          to_id: this.$refs.IMUI.currentContact.user_id,
          form: {
            id: e.fromUser.id,
            avatar: e.fromUser.avatar,
            name: e.fromUser.displayName,
          },
          message: {
            type: "text",
            content: {
              text: this.getEmojiValue(e.content),
            },
          },
          createtime: e.sendTime,
        });

        next();
        return false;
      }

      if (e.type === "image") {
        let formData = new FormData();
        formData.append("token", this.$store.state.token);
        formData.append("file", file);
        this.$axios.post("/api/common/upload", formData).then((res1) => {
          if (res1.data.code == 1) {
            this.$axios.post("/api/wanlshop/chat/send", {
              id: lastid,
              type: "chat",
              to_id: this.$refs.IMUI.currentContact.user_id,
              form: {
                id: e.fromUser.id,
                avatar: e.fromUser.avatar,
                name: e.fromUser.displayName,
              },
              message: {
                type: "img",
                content: {
                  url: res1.data.data.fullurl,
                },
              },
              createtime: e.sendTime,
            });
            next();
            return false;
          }
        });
      }

      // if (e.type === "image") {
      //   this.upload(file).then((res) => {
      //     if (res.status === 200) {
      //       this.ws.send(
      //         JSON.stringify({
      //           type: "say",
      //           data: {
      //             fromid: this.token,
      //             toid: e.toContactId,
      //             message: `img[${res.data.full_path}]`,
      //             message_type: "img",
      //           },
      //         })
      //       );
      //     }
      //   });
      //   next();
      //   return false;
      // }

      // this.ws.send(
      //   JSON.stringify({
      //     type: "say",
      //     data: {
      //       fromid: this.token,
      //       toid: e.toContactId,
      //       message: e.content,
      //       message_type: e.type,
      //     },
      //   })
      // );
      // next();
    },

    upload(file) {
      const params = this.$http.returnCommonParams(
        "api/Common/uploadPic",
        "chart"
      );
      let formData = new FormData();

      Object.keys(params).forEach((v) => {
        formData.append(v, params[v]);
      });
      formData.append("file", file);

      return axios.post(`api/Common/uploadPic`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    mapMsgType(e) {
      if (e.message_type === "img") {
        return "[Picture]";
      }

      if (e.message_type === "goods") {
        return "[商品]";
      }

      return e.message;
    },

    initContacts(e) {
      const list = [];
      e.forEach((v) => {
        list.push({
          id: v.id,
          user_id: v.user_id,
          displayName: v.name,
          avatar: v.avatar,
          unread: v.count,
          lastContent: this.$refs.IMUI.lastContentRender({
            type: "text",
            content: v.content,
          }),
        });
      });

      // this.$refs.IMUI.c

      this.$refs.IMUI.initContacts(list);
    },

    hide() {
      // console.log(this.$refs.IMUI.currentContact.user_id)

      if (this.$refs.IMUI.currentContact.user_id) {
        this.$axios
          .post("/api/wanlshop/chat/clear", {
            id: this.$refs.IMUI.currentContact.user_id,
          })
          .then((res) => {});
      }
      document.querySelector("body").style.overflow = "auto";
      this.contact = {
        user_id:0,
        unread:0
      };
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.im {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1111;
  width: 100%;
  height: 100%;

  .imui {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 111;
    transform: translate(-50%, -50%);

    .msg-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .msg-title__right {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          cursor: pointer;
          transition: all ease 0.5s;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

.imui {
  .lemon-messages {
    padding: 0 15px !important;
  }

  

  .lemon-message {
    margin: 10px 0;
    padding: 0 !important;
  }

  .lemon-message__title {
    margin-bottom: 8px;
  }

  .lemon-editor__emoji-item {
    width: 28px;
  }

  .lemon-container__title {
    border-bottom: 1px solid #e9e9e9;
  }

  .lemon-editor {
    border-top: 1px solid #e9e9e9;
  }

  .lemon-message-text .lemon-message__content img {
    width: auto;
  }

  .lemon-message-image .lemon-message__content img {
    max-width: 200px !important;
    min-width: auto !important;
    width: auto;
    height: auto !important;
  }
}

.mask {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
</style>
