<template>
 
  <div id="__nuxt" style="overflow: auto;height: 100vh;">
    
 
   <div v-if="$store.state.loadingHome" class="loading-wrapper">
    <div class="loading-container">
      <div class="cart-icon"></div>
      <span class="loading-text">Loading...</span>
    </div>
  </div>
  <div v-else  class="demo-image__lazy" >
    <div id="__layout">
      <!-- <div style="width: 100vw;height: 100vh;background-color: #fff;position: fixed;display: flex;justify-content: center;align-items: center;" v-if="loadingHome">
        <img style="width: 100vw;height: auto;" src="/image/R-C.gif"/>
        
      </div> -->

      <div >
        <div data-v-3df7a256="">
          <div data-v-3df7a256="" class="index_banner">
            <img data-v-3df7a256="" src="https://www.magasings.com/" />
          </div>
        </div>
        <HeaderTop></HeaderTop>

        <div data-v-020511ba="">
          <div data-v-020511ba="" class="index wrapper_1200">
            <div data-v-020511ba="" class="categoryMain">
              <div data-v-020511ba="" class="wrapper_1200">
                <div data-v-020511ba="" class="list wrapper_1200 acea-row row-middle">
                  <div data-v-020511ba="" class="categoryBtn">{{ $t("classify.classify") }}</div>
                  <a data-v-020511ba="" class="item" @click="$router.push('/goods_cate')">{{ $t("text4") }} </a>
                  <!-- <a
                    data-v-020511ba=""
                    class="item"
                    @click="$router.push('/shop_cate')"
                    >{{ $t("text5") }}
                  </a> -->
                </div>
              </div>
            </div>
            <div data-v-020511ba="" class="wrapper">
              <div data-v-020511ba="" class="slider-banner banner">
                <div data-v-020511ba="" v-loading="loading"
                  class="swiper-container swiper-container-initialized swiper-container-horizontal">
                  <div data-v-020511ba="" class="swiper-wrapper" style="
                      transform: translate3d(-3600px, 0px, 0px);
                      transition-duration: 1000ms;
                    ">
                    <div data-v-020511ba="" v-for="(item, index) in banner_list" :key="index" class="swiper-slide"
                      style="margin-left: 208px;width: 992px">
                      <img data-v-020511ba="" style="width: 992px" :src="item.image" />
                    </div>
                  </div>
                  <!-- <div data-v-020511ba="" slot="pagination"
											class="swiper-pagination paginationBanner swiper-pagination-clickable swiper-pagination-bullets">
											<span class="swiper-pagination-bullet swiper-pagination-bullet-active"
												tabindex="0" role="button" aria-label="Go to slide 1"></span><span
												class="swiper-pagination-bullet" tabindex="0" role="button"
												aria-label="Go to slide 2"></span></div> -->
                  <!-- <div data-v-020511ba="" slot="pagination" class="swiper-button-prev"
											tabindex="0" role="button" aria-label="Previous slide"></div> -->
                  <!-- <div data-v-020511ba="" slot="pagination" class="swiper-button-next"
											tabindex="0" role="button" aria-label="Next slide"></div><span
											class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
-->
                </div>
              </div>
              <div data-v-020511ba="" class="category acea-row row-middle">
                <div data-v-020511ba="" class="sort sort-scroll">
                  <div data-v-020511ba="" @click="goods_cate(item)" v-for="(item, index) in $store.state.init_data?.modulesData
                    ?.categoryModules" :key="index" class="item acea-row row-between-wrapper">
                    <div data-v-020511ba="" class="name line1">
                      {{ item.name }}
                    </div>
                    <div data-v-020511ba="" class="iconfont icon-you"></div>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-020511ba="" class="seckill acea-row row-middle"></div>
            <div data-v-020511ba="" id="page2" class="boutique">
              <div data-v-020511ba="" class="publicTitle acea-row row-between-wrapper">
                <div data-v-020511ba="" class="title acea-row row-middle"></div>
              </div>
            </div>
            <div data-v-020511ba="" id="page3" class="newGoods">
              <div data-v-020511ba="" class="publicTitle acea-row row-between-wrapper">
                <div data-v-020511ba="" class="title acea-row row-middle">
                  <div data-v-020511ba="" class="pictrue">{{ $t('index.Recommended') }}</div>
                </div>

                <a data-v-020511ba="" @click="getindex_goods_list()" class="more acea-row row-center-wrapper">
                  {{ $t("replace") }}
                  <el-icon class="el-icon-refresh"></el-icon>
                </a>
              </div>
              <div data-v-020511ba="" v-loading="loading" class="list acea-row row-middle" style="display: flex">
                <div @click="
                  $router.push({
                    path: '/productDetails',
                    query: {
                      id: item.id,
                    },
                  })
                  " v-for="(item, index) in goods_list.list" :key="index" data-v-020511ba="" class="item">
                  <div data-v-020511ba="" class="pictrue">
                    <el-image :src="item.goods.image" style="width: 192px; height: 192px" :fit="'scale-down'"
                      lazy></el-image>
                    <!-- <img data-v-020511ba=""
											:src="item.goods.image"> -->
                  </div>
                  <div data-v-020511ba="" class="name line1">
                    {{ item.goods.title }}
                  </div>
                  <div data-v-020511ba="" class="money line1">
                    <span data-v-020511ba="" class="label" style="padding: 0 10px;">{{ $t("product.list.price")
                      }}</span>
                    <span data-v-020511ba="" class="font-color">{{ item.goods.hb_unit }}
                      {{ item.goods.price.toFixed(2) }}</span>
                  </div>
                  <div data-v-020511ba="" class="stock">
                    {{ $t("product.goods.sales") }} {{ item.sales }}
                  </div>
                </div>
              </div>
            </div>

            <div data-v-020511ba="" id="page3" class="newGoods">
              <div data-v-020511ba="" class="publicTitle acea-row row-between-wrapper">
                <div data-v-020511ba="" class="title acea-row row-middle">
                  <div data-v-020511ba="" class="pictrue">{{ $t("index.hot") }}</div>
                </div>
                <a data-v-020511ba="" @click="getindex_goods_list()" class="more acea-row row-center-wrapper">
                  {{ $t("replace") }}
                  <el-icon class="el-icon-refresh"></el-icon>
                </a>
                <!-- <a data-v-020511ba="" href="https://www.magasings.com/goods_list?type=new"
										class="more acea-row row-center-wrapper">
										更多<span data-v-020511ba="" class="iconfont icon-you"></span></a> -->
              </div>
              <div data-v-020511ba="" v-loading="loading" class="list acea-row row-middle" style="display: flex">
                <div  @click="
                  $router.push({
                    path: '/productDetails',
                    query: {
                      id: item.id,
                    },
                  })
                  " v-for="(item, index) in goods_list.list2" :key="index" data-v-020511ba="" class="item">
                  <div data-v-020511ba="" class="pictrue">
                    <el-image lazy :src="item.goods.image" style="width: 192px; height: 192px"
                      :fit="'scale-down'"></el-image>
                  </div>
                  <div data-v-020511ba="" class="name line2">
                    {{ item.goods.title }}
                  </div>
                  <div data-v-020511ba="" class="money line1">
                    <span data-v-020511ba="" style="margin-left: 0" class="font-color">{{ item.goods.hb_unit }}
                      {{ item.goods.price.toFixed(2) }}</span>
                  </div>
                  <!-- <div data-v-020511ba="" class="stock">销量 {{item.sales}}</div> -->
                </div>
              </div>
            </div>

            <div data-v-020511ba="" id="page3" class="newGoods">
              <div data-v-020511ba="" class="publicTitle acea-row row-between-wrapper">
                <div data-v-020511ba="" class="title acea-row row-middle">
                  <div data-v-020511ba="" class="pictrue">{{ $t("goodList") }}</div>
                </div>
                <a data-v-020511ba="" @click="getgoods_list()" class="more acea-row row-center-wrapper">
                  {{ $t("replace") }}
                  <el-icon class="el-icon-refresh"></el-icon>
                </a>
              </div>
              <div v-loading="loading1" data-v-020511ba="" class="list acea-row row-middle" style="display: flex">
                <div @click="
                  $router.push({
                    path: '/productDetails',
                    query: {
                      id: item.id,
                    },
                  })
                  " v-for="(item, index) in goods_list2" :key="index" data-v-020511ba="" class="item">
                  <div data-v-020511ba="" class="pictrue">
                    <el-image lazy :src="item.goods.image" style="width: 192px; height: 192px"
                      :fit="'scale-down'"></el-image>
                  </div>
                  <div data-v-020511ba="" class="name line2">
                    {{ item.goods.title }}
                  </div>
                  <div data-v-020511ba="" class="money line1">
                    <span data-v-020511ba="" style="margin-left: 0" class="font-color">{{ item.goods.hb_unit }}
                      {{ item.goods.price.toFixed(2) }}</span>
                  </div>
                  <!-- <div data-v-020511ba="" class="stock">销量 {{item.sales}}</div> -->
                </div>
              </div>
            </div>

            <div data-v-020511ba="" id=" page4" class="classify">
              <div data-v-020511ba="" class="classify-title">
                <span data-v-020511ba="">{{ $t("tabBar.classify") }}</span>
              </div>
            </div>

            <div v-for="(item1, index1) in  $store.state.init_data?.modulesData?.categoryModules" :key="index1" data-v-020511ba=""
              id="page3" :class="'newGoods newGoods' + index1" v-if="page > index1">
              <div data-v-020511ba="" class="publicTitle acea-row row-between-wrapper">
                <div data-v-020511ba="" class="title acea-row row-middle">
                  <div data-v-020511ba="" class="pictrue">{{ item1.name }}</div>
                </div>
                <a data-v-020511ba="" @click="
                  $router.push({
                    path: '/goods_cate',
                    query: {
                      category_id: item1.id,
                      name: item1.name,
                    },
                  })
                  " class="more acea-row row-center-wrapper">
                  {{ $t("text7") }}
                  <el-icon class="el-icon-arrow-right"></el-icon>
                </a>
              </div>

              <div  data-v-020511ba="" class="list acea-row row-middle" style="display: flex">
                <div @click="
                  $router.push({
                    path: '/productDetails',
                    query: {
                      id: item.id,
                    },
                  })
                  " v-for="(item, index) in item1?.data" :key="index" data-v-020511ba="" class="item">
                  <div data-v-020511ba="" class="pictrue">
                    <el-image :src="item.goods.image" style="width: 192px; height: 192px" :fit="'scale-down'"
                      lazy></el-image>
                  </div>
                  <div data-v-020511ba="" class="name line2">
                    {{ item.goods.title }}
                  </div>
                  <div data-v-020511ba="" class="money line1">
                    <span data-v-020511ba="" style="margin-left: 0" class="font-color">{{ item.goods.hb_unit }}
                      {{ item.goods.price.toFixed(2) }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div id="loadmore" style="text-align: center;"
              v-if=" $store.state.init_data?.modulesData?.categoryModules.length > page">
              <i class="el-icon-loading"></i>
            </div>
            <div id="loadmore" style="text-align: center;" v-else>

            </div>

          </div>
        </div>
        <BottomFixed></BottomFixed>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import HeaderTop from "../components/HeaderTop.vue";
import BottomFixed from "../components/BottomFixed.vue";
import { Loading } from 'element-ui';
import $ from "jquery";

// import translate from "translate"
// import Swiper from "swiper";
export default {
  components: {
    HeaderTop,
    BottomFixed,
  },
  data() {
    return {
      goods_list: {},
      goods_list2: [], //推荐
      init_data: {},
      lists_data: [],
      index_data: {},
      banner_list: [],
      loading: true,
      loading1: true,
      app_url: "",
      page: -1,
      loadingMore: false,
      loadingHome:true
    };
  },
  methods: {
    loadmore() { },
    getRandomChar() {
      const charCodeA = "a".charCodeAt(0);
      const charCodeZ = "z".charCodeAt(0);
      const randomCharCode =
        charCodeA + Math.random() * (charCodeZ - charCodeA);
      return String.fromCharCode(randomCharCode);
    },
    goods_cate(item) {
      this.$router.push({
        path: "/goods_cate",
        query: {
          category_id: item.id,
          name: item.name,
        },
      });
    },

    getindex_goods_list() {
      this.loading = true;
      this.$axios({
        url: "/api/index/index_goods_list",
        method: "POST",
      }).then((res) => {
        console.log(res.data);
        this.goods_list = res.data.data;
        this.loading = false;
      });
    },
    getgoods_list() {
      this.loading1 = true;
      this.$axios({
        url: "/api/index/goods_list",
        method: "POST",
      }).then((res) => {
        console.log(res.data);
        this.goods_list2 = res.data.data;
        this.loading1 = false;
      });
    },
    getinit() {
      this.$store.dispatch("getinit")
      // this.init_data = this.$store.state.init_data;
      // this.loadingHome =  this.$store.state.loadingHome;
     

  //     this.$axios({
  //       url: "/api/wanlshop/common/init",
  //       method: "POST",
  //     }).then((res) => {
  //       if (res.data.code == 1) {
        
  //         this.init_data = res.data.data;
  //         let arr = this.init_data.modulesData.categoryModules;


  //         let lang = "en";

	// 		switch (window.localStorage.getItem("country")){
  //       case "kor":
	// 					   lang = "ko"
	// 						break;
			
	//  	case "zh-Hans":
	// 	   lang = "zh"
	//  		break;
			
				
				
	// 				case "zh-Hant":
	// 				   lang = "zh-TW"
	// 					break;

  //           case "vnm":
	// 				   lang = "vi"
	// 					break;
					
	// 						case "jp":
	// 						   lang = "ja"
	// 							break;
	// 							case "xby":
	// 							   lang = "es"
	// 								break;
	// 								case "ydl":
	// 								   lang = "it"
	// 									break;
									
	// 										case "dy":
	// 										   lang = "de"
	// 											break;
	// 											case "pty":
	// 											   lang = "pt"
	// 												break;
	//  	default:
	//  		break;
	//  }

  //  this.init_data.modulesData.categoryModules.forEach(item => {
  //   translate(item.name, {from:"en", to: lang }).then(res => {
			
  //   item.name = res
  // })
  //  })

         
  //         this.init_data = res.data.data

  //         window.localStorage.setItem("m_url",res.data.data.m_url)
  //         if (window.screen.width < 500) {
            
         
  //         window.location.href = window.localStorage.getItem("m_url");
          
  //       }
  //       setTimeout(() => {
  //         this.loadingHome = false
  //       }, 2000);
        
         
  //       }

        
  //     });
    },

    getlists() {
      this.$axios({
        url: `/api/wanlshop/product/lists?type=shop&search=${this.getRandomChar()}&page=1&sort=sales`,
        method: "GET",
      }).then((res) => {
        console.log(res.data);
        this.lists_data = res.data.data.data;
        // var swiper = new Swiper("#swiper-container2", {  slidesPerView: 3,
        //     spaceBetween: 30,
        //     freeMode: true,
        //     autoplay: true,});
      });
    },

    getindex() {
      this.$axios({
        url: "/api/index/index",
        method: "POST",
      }).then((res) => {
        console.log(res.data);
        this.index_data = res.data.data;
        this.banner_list = res.data.data.banner;
        this.app_url = res.data.data.app_url;
        this.$store.state.app_url =res.data.data.app_url;
        window.localStorage.setItem("app_url", res.data.data.app_url);

        if (res.data.data.banner) {
          setTimeout(() => {
            new Swiper(".swiper-container", { autoplay: true });
          });
          this.loading = false;
        }
      });
    },

    loadmore_cate() {
     
      this.page++;
      
      this.$store.state.init_data.modulesData.categoryModules.forEach((item, index) => {
          
          if (this.page == index)
            this.$axios({
              url: `/api/wanlshop/product/lists?search=&sort=shop_goods.createtime&order=desc&page=1&filter={%22category_id%22:${item.id},%22shop_goods.price%22:%22,%22}&op={%22category_id%22:%22in%22,%22shop_goods.price%22:%22BETWEEN%22}&type=goods`,
              methods: "GET",
            }).then((res1) => {
              if (res1.data.code == 1) {
                item.data = res1.data.data.data;
               this.loadingMore  = false
                // this.$forceUpdate();
              }
            });
        });








    },

    // follow(item){

    //   console.log(item)
    //   this.$axios({
    //     url:"/api/wanlshop/product/follow",
    //     method:"POST",
    //     data:{
    //       id:item.id,
    //       shop_goods_id:item.goods_id
    //     }
    //   }).then(res => {

    //   })
    // }
  },
  mounted() {


    this.$store.state.loadingHome = true
    // Loading.service({ fullscreen: true,text:"loading",background:"black" });

    

    this.getindex();
    this.getindex_goods_list();
    this.getinit();
    this.getgoods_list();

    setTimeout(() => {

      var box = document.getElementById('__nuxt');
      box.addEventListener("scroll", () => {
      let ele = document.getElementById("loadmore")
      
      if (window.innerHeight > ele.getBoundingClientRect().top - 100 && !this.loadingMore) {

        this.loadingMore  = true
       
        // this.loadingMore = true;

        this.loadmore_cate()



      }


    })
    }, 2000);
    // this.getlists()
  },
};
</script>

<style scoped>
.index .newGoods .list .item~.item[data-v-020511ba] {
  margin-left: 0px;
  margin-bottom: 14px;
}

.index .newGoods .list {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
}

.name line2 {
  min-height: 40px;
}

.title .pictrue {
  width: unset !important;
  color: #000;
  font-weight: bold;
}

.more {

  width: auto !important;
  padding: 0 20px;
}
.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* 使容器高度全屏，便于居中 */
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #333;
}

.cart-icon {
  width: 32px;
  height: 32px;
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 fill=%22%23333%22 width=%2232%22 height=%2232%22 viewBox=%220 0 24 24%22%3E%3Cpath d=%22M7 4H2V6H3.7L6.6 13.59L5.25 16.04C5.09 16.34 5 16.66 5 17C5 18.1 5.9 19 7 19H19V17H7.42C7.28 17 7.18 16.89 7.18 16.75L7.2 16.7L8.1 15H15.55C16.3 15 16.96 14.58 17.3 13.97L21.88 6.99C22.07 6.66 22 6.26 21.76 5.97C21.51 5.68 21.13 5.5 20.71 5.5H5.21L4.27 3H2V4H4L7 4ZM17 18C15.9 18 15 18.9 15 20C15 21.1 15.9 22 17 22C18.1 22 19 21.1 19 20C19 18.9 18.1 18 17 18ZM7 18C5.9 18 5 18.9 5 20C5 21.1 5.9 22 7 22C8.1 22 9 21.1 9 20C9 18.9 8.1 18 7 18Z%22/%3E%3C/svg%3E');
  background-size: contain;
  background-repeat: no-repeat;
  animation: bounce 1s ease infinite;
  margin-right: 8px;
}

.loading-text {
  color: #555;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>