<template>
     <el-dialog :close-on-click-modal="false" :show-close="false" :title="$t('setting.payPassword')" :visible="$store.state.dialogZijin" @close="$store.state.dialogZijin = false">

<form data-v-e8f4fb74="" class="el-form form">
  <div data-v-e8f4fb74="" class="el-form-item is-required">
    <label for="newPassword" class="el-form-item__label">{{ $t("text21") }}</label>
    <div class="el-form-item__content">
      <el-input v-model="email" :placeholder="$t('passwordEdit.passwordEdit')"
        disabled></el-input>
    </div>
  </div>
  <div data-v-e8f4fb74="" class="el-form-item is-required">
    <label for="newPassword" class="el-form-item__label">{{ $t("text73") }}</label>
    <div class="el-form-item__content">
      <el-input v-model="newPassword" :placeholder="$t('paypassEdit.newpaypass')" type="password"
        show-password></el-input>
    </div>
  </div>
  <div data-v-e8f4fb74="" class="el-form-item is-required">
    <label for="confirmPassword" class="el-form-item__label">{{ $t("text74") }}</label>
    <div class="el-form-item__content">
      <el-input v-model="captcha" :placeholder="$t('register.code')">
        <template slot="append">

          <div @click="sendCode()" >{{ msg }}</div>
        
        </template>
      </el-input>
    </div>
  </div>
  <div data-v-e8f4fb74="" class="el-form-item">

    <div style="background-color: #e93323;text-align: center;color: #fff;border-radius: 5px" @click="submit()"
      class="el-form-item__content">

      {{ $t("confirm") }}

      <!-- <button
                 style="width: 100%;"
                  data-v-e8f4fb74=""
                  type="button"
                  class="el-button form-submit-btn el-button--submit"
                  block=""
                >
                  <span> {{$t("confirm")}} </span></button
                > -->
    </div>
  </div>
</form>
</el-dialog>
</template>

<script>
export default {
    data() {
        return{
            captcha: "",
      newPassword: "",
      msg: this.$t('getCode'),
      count: 60,
      timer: null,
      email:this.$store.state.userData?.userinfo?.email
        }
    },
    methods:{
     
        sendCode() {

if (this.msg == this.$t("getCode")) {
  this.$axios({
    url: "/api/ems/send",
    method: "POST",
    data: {
      email: JSON.parse(window.localStorage.getItem("userData")).userinfo.email || "",
      event: "changepwd",
    },
  }).then((res) => {
    if (res.data.code == 1) {
      this.count = 60;
      this.msg = `${this.count}${this.$t('uCodeChangeText')}`;
      this.timer = window.setInterval(() => {
        this.count--;
        this.msg = `${this.count}${this.$t('uCodeChangeText')}`;
        if (this.count == 0) {
          window.clearInterval(this.timer);

          this.msg = this.$t("getCode")
        }
      }, 1000);
    }
  });
} 

},
       submit() {

if (!this.newPassword) {
  this.$message.warning(this.$t("paypassEdit.newpaypass"))
  return
}
if(!(/^\d{6}$/.test(this.newPassword))){
          this.$message.warning(this.$t("text109"))
          return
        }
if (!this.captcha) {
  this.$message.warning(this.$t("passwordEdit.yxyzm"))
  return
}
this.$axios({
  method: "POST",
  url: "/api/wanlshop/user/pay_pass_edit",
  data: {
    captcha: this.captcha,
    payPass: this.newPassword,
  },
}).then((res) => {

  if (res.data.code == 1) {
    this.$message.success(this.$t("text75"))
    this.$store.state.dialogZijin = false
  }
});
},
    }
}

</script>