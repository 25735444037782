
import axios from "axios";
import { Message, MessageBox } from "element-ui";
import store from "@/store";
import router from "../router/index"
import { i18n } from '../locale/index.js' //国际化

import config from "./config.js";

import translate from "translate";


function kefu(type) {
  if (type == 1) {

    if (window.localStorage.getItem("token")) {

      window.open('/chatlink.html?groupid=d208b8d06677b67b4ccc8290392a1128&language=en&metadata={"email":"' + store.state.userData.userinfo.email + '"}', "_bank")
      //  window.location.href='/chatlink.html?groupid=870e6c6d86c4e3bc2efb752da09374b0&language=en&metadata={"email":"'+this.$store.state.userData.userinfo.email+'"}'
    } else {

      window.open('/chatlink.html?groupid=d208b8d06677b67b4ccc8290392a1128&language=en&metadata={"email":"' + '' + '"}', "_bank")
      //  window.location.href='/chatlink.html?groupid=870e6c6d86c4e3bc2efb752da09374b0&language=en&metadata={"email":"'+''+'"}'
    }
  } else {
    store.state.kefuDialog = true
  }
}




const http = axios.create({
  // baseURL: 'https://a.darty-ap.com'
  baseURL: config.HTTP_API_URL
  // baseURL: 'https://www.zalando-ap.cc'
});
// 请求超时时间
// axios.defaults.timeout = TIME_OUT;

// axios.defaults.withCredentials = true;

// 封装请求拦截
http.interceptors.request.use(
  config => {


    let token = localStorage.getItem("token");
    config.headers["Lang"] = store.state.country
    config.headers["mtype"] = store.state.moneyType
    if (token) {

      config.headers["Token"] = token.replace(/"/g, '')


    }

    return config;
  },
  error => {

    return Promise.reject(error);
  }

);

// 封装响应拦截，判断token是否过期
http.interceptors.response.use(
  response => {

    //   setTimeout(() => {
    //     translate.language.setLocal('english'); //设置本地语种（当前网页的语种）。如果不设置，默认自动识别当前网页显示文字的语种。 可填写如 'english'、'chinese_simplified' 等，具体参见文档下方关于此的说明。
    // translate.service.use('client.edge'); //设置机器翻译服务通道，直接客户端本身，不依赖服务端 。相关说明参考 http://translate.zvo.cn/43086.html
    // translate.execute();//进行翻译 
    //   },0)
    // alert(JSON.stringify(response) )

    if (response.data.code == 0) {



      let request1 = {
        "Account is incorrect": "Incorrect Account",
        "Account is locked": "The account has been frozen due to illegal operations, please contact customer service to deal with it",
        "Money Status is locked": "Due to illegal operations, the wallet function is temporarily frozen, please contact customer service for processing",
        "error": "Error",
        "ok": "Ok",
        "no address": "No Address",
        "no my shop": "Not Available",
        "Please activate IM instant messaging service": "Please activate IM instant messaging service",
        "The other party is not a merchant, operation is prohibited": "The other party is not a merchant, operation is prohibited",
        "Please enter key words": "Please enter key words",
        "Please click above to add shipping address": "Please click above to add shipping address",
        "Inventory shortage": "Inventory shortage",
        "Insufficient balance": "Insufficient balance",
        "The order has exceeded the application time for after-sales": "The order has exceeded the application time for after-sales",
        "payment password error": "Payment password error",
        "Please enter the recharge amount": "Please enter the recharge amount",
        "Please select a recharge method": "Please select a recharge method",
        "Please upload the transfer attachment": "Please upload the transfer attachment",
        "The withdrawal amount is incorrect": "The withdrawal amount is incorrect",
        "The withdrawal amount exceeds the withdrawal limit": "The withdrawal amount exceeds the withdrawal limit",
        "Please enter the withdrawal address": "Please enter the withdrawal address",
        "Please select a withdrawal network": "Please select a withdrawal network",
        "The system should close the withdrawal function, please contact the platform customer service": "The system forbid withdrawal function, please contact the platform customer service",
        "The maximum number of withdrawals for this month has been reached": "The maximum number of withdrawals for this month has been reached",
        "please set the payment password": "Please set the payment password",
        "Captcha is incorrect": "Captcha is incorrect",
        "Invitation code does not exist": "Invitation code does not exist",
        "Email is incorrect": "Email is incorrect",
        "Email already exists": "Email already exist",
        "User not found": "User not found",
        "Password must be 6 to 30 characters": "Password must be 6 to 30 characters",
        "Send Frequently": "Multiple Input",
        "Unregistered": "Unregistered",
        "Nickname is already taken": "Nickname has been taken",
        "Email does not exist": "Email does not exist",
        "sj_zdjg": " Due to low status of store, item above $300 is not allowed",
        "sj_zdsps": "Exceeded item upload Limit",
        "Processing": "Under review",
        "Please contact customer service to lower the store level and then apply for withdrawal": "Please contact customer service to lower the store level and then apply for withdrawal",
        "no_order": "The system detected that your area does not support delivery due to logistics reasons, please contact customer service for further details."
      }
      // alert(i18n.t("request"[response.data.msg]))
      let str = response.data.msg

      if (request1[`${str}`]) {
        Message.error(i18n.t(`request['${str}']`))
      } else {
        let lang = "en";

        switch (window.localStorage.getItem("country")) {
          case "kor":
            lang = "ko"
            break;

          case "zh-Hans":
            lang = "zh"
            break;



          case "zh-Hant":
            lang = "zh"
            break;

          case "vnm":
            lang = "vi"
            break;

          case "jp":
            lang = "ja"
            break;
          case "xby":
            lang = "es"
            break;
          case "ydl":
            lang = "it"
            break;

          case "dy":
            lang = "de"
            break;
          case "pty":
            lang = "pt"
            break;
          default:
            break;
        }


        translate(str, { from: "en", to: lang }).then(res => {

          Message.error(res)
        })

      }

      console.log(request1, str, request1[`${str}`])
      // Message.error(i18n.t(`request['${str}']`))
    }





    return Promise.resolve(response);

  },
  error => {
    // console.log(error.response.data)
    if (error.response.data.code == 401) {
      Message.error(i18n.t("text94"))
      router.push("/")
      return
    }

    if (error.response.data.code == 402) {

      MessageBox.confirm(i18n.t("text110"), i18n.t("text45"), {
        confirmButtonText: i18n.t("text111"),
        cancelButtonText: i18n.t("text112"),
        type: "warning",
      }).then(res => {
        kefu(1)

      }).catch(err => {
        router.push("/")
      })

      return
    }




    //   return Promise.reject(error);
  }
);

export default http


